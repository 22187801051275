<template>
   <!-- 放置二维码的容器,需要给一个ref -->
   <div id="box">
      <div id="qrcode"></div>
      <h3>扫码评估</h3>
   </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
   data () {
      return {};
   },

   mounted () {
      this.qrcode();
   },
   methods: {
      qrcode () {
         let qrcode = new QRCode("qrcode", {
            width: 200,
            height: 200, // 高度
            // text: "http://chongqingpsyqj.cloudervr.com/#/AssessmentLogin", // 二维码内容
            text: "http://chongqingpsy.cloudervr.com/#/AssessmentLogin", // 二维码内容
            // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            background: "#f0f",
            foreground: "#ff0",
         });
         // console.log(qrcode);
      },
   },
};
</script>

<style lang='scss' scoped>
#box {
   height: 300px;
}
#qrcode {
   width: 200px;
   height: 200px;
   margin: auto;
}
h3 {
   text-align: center;
}
</style>
